import React, { useEffect, useState } from "react";
import {
  Box,
  Heading,
  Text,
  VStack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Image,
  List,
  ListItem,
  Spinner,
  Center,
  Button,
  IconButton,
  HStack,
  Divider,
  ListIcon,
  Icon,
  Spacer,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Article from "./Article";
import ChoicesList from "./ChoicesList";
import ImageStream from "./ImageStream";
import ErrorIndicator from "./ErrorIndicator";
import { FaRegCopyright } from "react-icons/fa";

import { Link } from "react-router-dom";
import { IoLogoInstagram, IoLogoWhatsapp } from "react-icons/io5";
import { eventUrl, instagramLink, whatsappLink } from "../constants.js";

const HomePage = () => {
  const [news, setNews] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const selectedImageDocId = "L6UeybzCFSUwquNnwtGM";
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchNews = async (category = null) => {
    setLoading(true);
    setError(null);

    try {
      let q;
      if (category) {
        q = query(
          collection(db, "events"),
          where("category", "==", category),
          orderBy("date", "desc") // Order by date in descending order
        );
      } else {
        q = query(
          collection(db, "events"),
          orderBy("date", "desc") // Order by date in descending order
        );
      }

      const newsSnapshot = await getDocs(q);
      const newsList = newsSnapshot.docs.map((doc) => doc.data());

      setNews(newsList);
    } catch (err) {
      console.error("Error fetching Events: ", err);
      setError(`Error fetching events: ${err.message}`); // Updated error message
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  return (
    <Box pb={4} bgGradient="linear(to-b, violet, blue)">
      <HStack p={4}>
        <IconButton
          icon={<HamburgerIcon />}
          aria-label="Open Menu"
          bg={"white"}
          color={"black"}
          onClick={onOpen}
          _hover={{ bg: "blue", color: "white" }}
          mb={4}
        />

        <HStack align="center" justify="center" w={"100%"}>
          <Image src="logo.png" boxSize={"70px"}></Image>
          <Heading as="h1" size="lg" color="white">
            Dubai.Events
          </Heading>
        </HStack>
      </HStack>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bgGradient="linear(to-b, violet, blue)">
          <DrawerCloseButton />
          <DrawerBody display="flex" flexDirection="column">
            <DrawerHeader pb={10}>
              <Image src="/logo.png" />
              <Divider orientation="vertical" height="1px" />
            </DrawerHeader>

            <List spacing="20px" flex="1">
              <ListItem>
                <a
                  href={instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HStack>
                    <Image src="/instagram.svg" boxSize={10} alt="Instagram" />
                    {/* <ListIcon as={IoLogoInstagram} boxSize={10} color="black" /> */}
                    <Text color="black" fontSize="lg">
                      Instagram
                    </Text>
                  </HStack>
                </a>
              </ListItem>
              <ListItem>
                <a
                  href={whatsappLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HStack>
                    <Image src="/whatsapp.svg" boxSize={10} alt="Instagram" />
                    {/* <ListIcon as={IoLogoWhatsapp} boxSize={10} color="black" /> */}
                    <Text color="black" fontSize="lg">
                      Contact-us
                    </Text>
                  </HStack>
                </a>
              </ListItem>

              <Spacer height={"48%"} />
              <ListItem mt="auto" display="flex" justifyContent="center">
                <a href={eventUrl} target="_blank" rel="noopener noreferrer">
                  <Text as="u" textAlign="center">
                    Powered By www.event.events
                  </Text>
                </a>
              </ListItem>

              <ListItem alignSelf="flex-end" mt="auto">
                <HStack spacing={4}>
                  <Link to="/admin" style={{ textDecoration: "none" }}>
                    <FaRegCopyright size={40} color="white" />
                  </Link>
                  <Text color="white" fontSize="lg">
                    All copyrights reserved under the global law of ICANN ®
                  </Text>
                </HStack>
              </ListItem>
            </List>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box mb={4} w="100%">
        <ImageStream docId={selectedImageDocId} />
      </Box>

      <Heading pl={4} pr={4} as="h2" size="md" color="white" mb={4}>
        Cities
      </Heading>
      <Box pl={4} pr={4}>
        <ChoicesList
          selectedCategory={selectedCategory}
          setSelectedCategory={(category) => {
            setSelectedCategory(category);
            fetchNews(category);
          }}
        />
        {loading ? (
          <Center h="100%">
            <Spinner size="xl" />
          </Center>
        ) : error ? (
          <Center h="100%">
            <Text color="red.500">{error}</Text>
          </Center>
        ) : news.length === 0 ? (
          <Center h="100%">
            <VStack>
              <Image
                src="/no_data.png"
                height={{ base: "100px", md: "300px" }}
                objectFit="cover"
                fallback={<Spinner size="xl" />}
              />
              <Box height={"5px"} />
              <Text fontWeight={"bold"} fontSize={18}>
                No news available for the moment
              </Text>
              <Box height={"5px"} />
            </VStack>
          </Center>
        ) : (
          <VStack spacing={4}>
            {news.map((article, index) => (
              <Article key={index} article={article} />
            ))}
          </VStack>
        )}
      </Box>
    </Box>
  );
};

export default HomePage;
