import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { Center, Spinner } from "@chakra-ui/react";

const ProtectedRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return (
      <Center h={"100vh"}>
        <Spinner />
      </Center>
    );
  }

  return user ? children : <Navigate to="/login" />;
};
export default ProtectedRoute;
