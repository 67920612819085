// src/App.js
import React from "react";
import { ChakraProvider, Box, Container } from "@chakra-ui/react";
import HomePage from "./components/HomePage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import theme from "./theme";
import LoginPage from "./components/LoginPage";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminPage from "./components/AdminPage";
import { NewsPageProvider } from "./providers/NewsPageContext";

function App() {
  return (
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Box>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/admin/*"
              element={
                <ProtectedRoute>
                  <NewsPageProvider>
                    <AdminPage />
                  </NewsPageProvider>
                </ProtectedRoute>
              }
            />
            {/* <Route path="/admin/settings" element={<AdminSettings />} /> */}
          </Routes>
        </Box>
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
