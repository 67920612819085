// src/components/ChoicesList.js
import React from "react";
import { Wrap, Button } from "@chakra-ui/react";
import { categories } from "../constants";

const ChoicesList = ({ selectedCategory, setSelectedCategory }) => {
  return (
    <Wrap spacing={2} mb={4}>
      {categories.map((category, index) => (
        <Button
          key={index}
          bg={
            category === "All" && !selectedCategory
              ? "yellow"
              : selectedCategory === category
              ? "yellow"
              : "white"
          }
          textColor="black"
          // variant={
          //   category === "All" && !selectedCategory
          //     ? "solid"
          //     : selectedCategory === category
          //     ? "solid"
          //     : "outline"
          // }
          //   colorScheme="teal"
          borderColor={"black"}
          onClick={() => {
            if (category == "All") {
              setSelectedCategory();
            } else {
              setSelectedCategory(category);
            }
          }}
        >
          {category}
        </Button>
      ))}
    </Wrap>
  );
};

export default ChoicesList;
