import { storage } from "../firebaseConfig"; // Your Firebase storage setup
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

export const uploadImage = async (file) => {
  const storageRef = ref(storage, `images/${file.name}`);
  await uploadBytes(storageRef, file);
  return await getDownloadURL(storageRef);
};

export const deleteImage = async (imageUrl) => {
  const fileRef = ref(storage, imageUrl);
  await deleteObject(fileRef);
};
