import React from "react";
import { Box, Flex, Text, Button, Image, Spinner } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const Article = ({ article }) => {
  const openLink = () => {
    try {
      window.open(article.link, "_blank");
    } catch (e) {
      console.error(e);
      alert("Invalid URL");
    }
  };

  return (
    <Box
      borderWidth="2px"
      borderRadius="lg"
      borderColor={"black"}
      overflow="hidden"
      boxShadow="md"
      w="100%"
      bg="#e9dccc"
      p={4}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="space-between"
      >
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb={{ base: 4, md: 0 }}
        >
          <Image
            src={article.image}
            alt={article.title}
            borderRadius="md"
            objectFit="fill"
            width="100%"
            height={{ base: "200px", md: "100%" }}
            maxHeight="400px"
            fallback={
              <Box
                height={{ base: "200px", md: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
              >
                <Spinner size="xl" />
              </Box>
            }
          />
        </Box>
        <Flex direction="column" align="center" flex="1" p={5}>
          <Text
            color={"black"}
            fontSize="2xl"
            fontWeight="bold"
            textAlign="center"
          >
            {article.title}
          </Text>
          <Text
            mt={2}
            color={"black"}
            fontSize="lg"
            maxW="lg"
            textAlign="center"
          >
            {article.content}
          </Text>
          {article.link && (
            <Button
              mt={4}
              onClick={openLink}
              rightIcon={<ExternalLinkIcon />}
              bg="purple"
            >
              Visit Link
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Article;
