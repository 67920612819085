// src/components/NewsPage.js
import React, { useContext, useState } from "react";
import {
  Box,
  Text,
  Spinner,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  IconButton,
  Button,
} from "@chakra-ui/react";
import NewsPageContext from "../providers/NewsPageContext";
import NewsItem from "./NewsItem";
import { useNavigate } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";
import { MdOutlineDeleteOutline } from "react-icons/md";

const NewsPage = () => {
  const { news, loading, deleteNews } = useContext(NewsPageContext);
  const toast = useToast();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedNewsItem, setSelectedNewsItem] = useState(null);

  const handleDelete = () => {
    if (selectedNewsItem) {
      deleteNews(
        selectedNewsItem,
        () => {
          toast({ title: "News deleted successfully", status: "success" });
          setIsOpen(false);
        },
        (error) => {
          toast({
            title: "Failed to delete news",
            description: error.message,
            status: "error",
          });
          setIsOpen(false);
        }
      );
    }
  };

  const openDeleteDialog = (newsItem) => {
    setSelectedNewsItem(newsItem);
    setIsOpen(true);
  };

  const cancelDelete = () => {
    setIsOpen(false);
    setSelectedNewsItem(null);
  };

  if (loading) {
    return <Spinner size="xl" />;
  }

  return (
    <Box p={4}>
      <Text mb={5} fontWeight="bold" fontSize="3xl">
        Events
      </Text>
      {news.length === 0 ? (
        <Text>No Event available</Text>
      ) : (
        news.map((newsItem) => (
          <NewsItem
            key={newsItem.id}
            news={newsItem}
            onDelete={() => openDeleteDialog(newsItem)}
          />
        ))
      )}

      {/* Floating Button to Add News */}
      <Button
        leftIcon={<AddIcon />}
        colorScheme="teal"
        position="fixed"
        bottom={4}
        right={4}
        onClick={() => navigate("/admin/news/add_news")}
        borderRadius="full"
        size="lg"
        zIndex={1}
      >
        Add Event
      </Button>

      <AlertDialog isOpen={isOpen} onClose={cancelDelete}>
        <AlertDialogOverlay color={"black"}>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Event
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this event item?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={cancelDelete}>Cancel</Button>

              <Button
                onClick={handleDelete}
                ml={3}
                leftIcon={<MdOutlineDeleteOutline color="red" />}
                colorScheme="whiteAlpha"
                variant="outline"
                color="red"
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default NewsPage;
