import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Spinner,
  Text,
  HStack,
  useToast,
  SimpleGrid,
  Center,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import {
  collection,
  getDoc,
  doc,
  addDoc,
  setDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { deleteImage, uploadImage } from "../utils/imageUploader";
import ErrorIndicator from "./ErrorIndicator";
import ImageComponent from "./ImageComponent";
import { AddIcon } from "@chakra-ui/icons";

const ImagesPage = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [highlightedImageUrl, setHighlightedImageUrl] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const imagesCollection = collection(db, "images");
    const unsubscribe = onSnapshot(
      imagesCollection,
      (snapshot) => {
        const imagesData = snapshot.docs.map((doc) => {
          const { id, ...rest } = doc.data();
          return {
            id: doc.id,
            ...rest,
          };
        });
        setImages(imagesData);
        setLoading(false);
      },
      (err) => {
        console.error(err);
        setError("Error fetching images");
        setLoading(false);
      }
    );

    fetchSelectedImage();

    return () => unsubscribe();
  }, []);

  const fetchSelectedImage = async () => {
    try {
      const selectedImageDoc = doc(db, "selectedImage", "L6UeybzCFSUwquNnwtGM");
      const snapshot = await getDoc(selectedImageDoc);
      if (snapshot.exists()) {
        const { url } = snapshot.data();
        setHighlightedImageUrl(url);
      }
    } catch (err) {
      console.error("Error fetching selected image:", err);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      try {
        const url = await uploadImage(file);
        await addDoc(collection(db, "images"), { url });
        toast({ title: "Image added successfully", status: "success" });
      } catch (err) {
        toast({ title: "Error adding image", status: "error" });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDeleteImage = async () => {
    setLoading(true);
    try {
      await deleteImage(selectedImageUrl);
      await deleteDoc(doc(db, "images", selectedImageId));
      toast({ title: "Image deleted successfully", status: "success" });
    } catch (err) {
      console.error(err);
      toast({ title: "Error deleting image document", status: "error" });
    } finally {
      setLoading(false);
      setDeleteDialogOpen(false);
    }
  };

  const handleImageClick = async (image) => {
    setHighlightedImageUrl(image.url);
    setSelectedImageUrl(image.url);
    await updateSelectedImage(image);
  };

  const updateSelectedImage = async (image) => {
    const selectedImageDoc = doc(db, "selectedImage", "L6UeybzCFSUwquNnwtGM");
    await setDoc(selectedImageDoc, { url: image.url });
  };

  if (loading) {
    return (
      <Center h={"100%"} w={"100%"}>
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return <ErrorIndicator message={error} onRetry={() => setLoading(true)} />;
  }

  return (
    <Box p={4}>
      <HStack spacing={4}>
        <Text fontWeight="bold" fontSize="3xl">
          Images
        </Text>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          hidden
        />
        <Button
          leftIcon={<AddIcon />}
          colorScheme="teal"
          position="fixed"
          bottom={4}
          right={4}
          onClick={() => document.querySelector('input[type="file"]').click()}
          borderRadius="full"
          size="lg"
          zIndex={1}
        >
          Upload Image
        </Button>
      </HStack>
      <Box mt={4}>
        {images.length === 0 ? (
          <Text>No Images available</Text>
        ) : (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
            {images.map((image) => (
              <ImageComponent
                key={image.id}
                image={image}
                isHighlighted={image.url === highlightedImageUrl}
                onClick={() => handleImageClick(image)}
                onDelete={() => {
                  setSelectedImageId(image.id);
                  setSelectedImageUrl(image.url);
                  setDeleteDialogOpen(true);
                }}
              />
            ))}
          </SimpleGrid>
        )}
      </Box>

      <AlertDialog
        isOpen={isDeleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <AlertDialogOverlay color={"black"}>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Image
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text color={"black"}>
                Are you sure you want to delete this image?
              </Text>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
              <Button colorScheme="red" onClick={handleDeleteImage} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ImagesPage;
