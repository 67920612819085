// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB3do0B966uz81lmPipETC8QT43estsums",
  authDomain: "uaeevents-b53eb.firebaseapp.com",
  projectId: "uaeevents-b53eb",
  storageBucket: "uaeevents-b53eb.firebasestorage.app",
  messagingSenderId: "833440750936",
  appId: "1:833440750936:web:555e39481755647cc8592d",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, storage, auth };
