// theme.js
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "Roboto, sans-serif",
    body: "Roboto, sans-serif",
  },
  components: {
    Button: {
      variants: {
        solid: {
          bg: "#00b8ff",
          color: "white",
          _hover: {
            bg: "gray.100",
            color: "black",
            boxShadow: "lg",
          },
        },
      },
      defaultProps: {
        variant: "solid",
      },
    },
  },
  styles: {
    global: {
      "html, body": {
        color: "white",
        bg: "black", // Optional: set a dark background to make white text visible
      },
    },
  },
});

export default theme;
