// src/components/NewsItem.js
import React from "react";
import { Box, Text, Image, Button, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdOutlineDeleteOutline } from "react-icons/md";

const NewsItem = ({ news, onDelete }) => {
  const navigate = useNavigate();

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      borderColor={"black"}
      overflow="hidden"
      p={4}
      mb={4}
    >
      {news.image && (
        <Image src={news.image} alt={news.title} borderRadius="md" w={"100%"} />
      )}
      <Text mt={2} fontWeight="bold" fontSize="xl">
        {news.title}
      </Text>
      <Text mt={2}>{news.content}</Text>
      <Stack direction="row" spacing={4} mt={4}>
        <Button onClick={() => navigate(`/admin/news/${news.id}`)}>Edit</Button>
        <Button
          leftIcon={<MdOutlineDeleteOutline color="red" />} // Set icon color to red
          colorScheme="whiteAlpha" // Use white background
          variant="outline" // Outline variant for a clean look
          color="red" // Set text color to red
          onClick={() => onDelete(news)}
        >
          Delete
        </Button>
      </Stack>
    </Box>
  );
};

export default NewsItem;
