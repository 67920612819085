export const instagramLink = "https://www.instagram.com/uae.events.are";
export const whatsappLink = "https://wa.me/message/AHMMLA6UQTAXA1";
export const eventUrl = "https://www.event.events";
export const categories = [
  "All",
  "Abu-Dhabi",
  "Dubai",
  "Sharjah",
  "Fujairah",
  "Ajman",
  "Umm Al-Quwain",
  "Ras Al-Khaimah",
];
export const generateUniqueFileName = (extension) => {
  const now = Date.now();
  const randomBytes = new Uint8Array(6);
  window.crypto.getRandomValues(randomBytes);
  const hex = Array.from(randomBytes)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  return `${now}.${hex}.${extension}`;
};
