// src/context/NewsPageProvider.js
import React, { createContext, useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db, storage } from "../firebaseConfig"; // Make sure to configure Firebase
import { ref, deleteObject } from "firebase/storage";

const NewsPageContext = createContext();

export const NewsPageProvider = ({ children }) => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const q = query(collection(db, "events"), orderBy("date", "desc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newsList = snapshot.docs.map((doc) => {
        const { id, ...rest } = doc.data();
        return {
          id: doc.id,
          ...rest,
        };
      });

      setNews(newsList);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const deleteNews = async (newsItem, onSuccess, onFail) => {
    try {
      await deleteObject(ref(storage, newsItem.image));
      await deleteDoc(doc(db, "events", newsItem.id));
      onSuccess();
    } catch (error) {
      onFail(error);
    }
  };

  return (
    <NewsPageContext.Provider value={{ news, loading, deleteNews }}>
      {children}
    </NewsPageContext.Provider>
  );
};

export default NewsPageContext;
