import React, { useEffect, useState } from "react";
import { Box, Image, Spinner, Text, Center } from "@chakra-ui/react";
import { db } from "../firebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";

const ImageStream = ({ docId }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const imageDocRef = doc(db, "selectedImage", docId);

    const unsubscribe = onSnapshot(
      imageDocRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          setImageUrl(docSnapshot.data().url);
        } else {
          setError("Document does not exist");
        }
        setLoading(false);
      },
      (err) => {
        console.error("Error fetching document: ", err);
        setError("Something went wrong");
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [docId]);

  if (loading) {
    return (
      <Center width="100%" height={{ base: "300px", md: "600px" }}>
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center width="100%" height={{ base: "300px", md: "600px" }}>
        <Text>{error}</Text>
      </Center>
    );
  }

  return (
    <Box width="100%" height={{ base: "300px", md: "600px" }}>
      <Image
        src={imageUrl}
        width="100%"
        height="100%"
        objectFit="cover"
        fallback={
          <Center width="100%" height="100%">
            <Spinner size="xl" />
          </Center>
        }
      />
    </Box>
  );
};

export default ImageStream;
