import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig";

const LoginPage = () => {
  const toast = useToast();
  const [user, loading] = useAuthState(auth);
  const [errorMessage, setErrorMessage] = useState("");
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    try {
      const { email, password } = values;
      const loginResponse = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (!loginResponse.user) {
        throw new Error("Login failed");
      } else {
        toast({
          title: "Connexion Success",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
        navigate("/admin");
      }
    } catch (error) {
      console.log(error.message);
      setErrorMessage(error.message);
    }
  };

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");

  if (loading) {
    return (
      <Center h="100vh">
        <Spinner />
      </Center>
    );
  }

  if (user) {
    return <Navigate to="/admin" />;
  }

  return (
    <Flex minH="100vh" align="center" justify="center" bg={"black"}>
      <Stack spacing={8} mx="auto" maxW="lg" py={12} px={6}>
        <Center>
          <Heading color={"white"} fontSize="4xl">
            Login
          </Heading>
        </Center>
        <Box rounded="lg" bg={boxBgColor} boxShadow="lg" p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <FormControl id="email" isInvalid={errors.email}>
                <FormLabel color={"black"}>Email</FormLabel>
                <Input
                  type="text"
                  color={"black"}
                  {...register("email", {
                    required: "Email Required",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: "Invalid email address",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={errors.password}>
                <FormLabel color={"black"}>Password</FormLabel>
                <Input
                  color={"black"}
                  type="password"
                  {...register("password", {
                    required: "Password Required",
                    minLength: {
                      value: 6,
                      message: "Minimum length should be 6",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
              <Stack direction="column">
                {errorMessage ? (
                  <Text fontSize={12} textColor="red">
                    {errorMessage}
                  </Text>
                ) : null}
                <Button
                  type="submit"
                  bg="black"
                  color="white"
                  _hover={{
                    bg: "gray.500",
                  }}
                  isLoading={isSubmitting}
                >
                  Sign In
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default LoginPage;
