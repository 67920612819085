import React from "react";
import { Box, Button, Center, Text } from "@chakra-ui/react";
import { MdRefresh } from "react-icons/md";

const ErrorIndicator = ({ message, onRetry }) => {
  return (
    <Center h={"100%"}>
      <Box p={4}>
        <Text color="red.600">{message}</Text>
        <Center>
          <Button
            leftIcon={<MdRefresh color="white" />}
            mt={2}
            colorScheme="red"
            onClick={onRetry}
          >
            Retry
          </Button>
        </Center>
      </Box>
    </Center>
  );
};

export default ErrorIndicator;
