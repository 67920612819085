import React from "react";
import { Box, Image, IconButton, Tooltip } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

const ImageComponent = ({ image, isHighlighted, onClick, onDelete }) => {
  return (
    <Box
      borderWidth={isHighlighted ? "2px" : "1px"}
      borderColor={isHighlighted ? "yellow" : "gray.200"}
      borderRadius="md"
      overflow="hidden"
      onClick={onClick}
      cursor="pointer"
      p={1}
      position="relative"
      _hover={{ boxShadow: "md" }} // Optional hover effect
    >
      <Image h={"100%"} src={image.url} alt={`Image ${image.id}`} />
      <IconButton
        icon={<DeleteIcon />}
        aria-label="Delete Image"
        size="sm"
        position="absolute"
        top={2}
        right={2}
        colorScheme="red"
        onClick={(e) => {
          e.stopPropagation(); // Prevents the click from triggering the onClick
          onDelete();
        }}
      />
    </Box>
  );
};

export default ImageComponent;
